module.exports = {
    // close information modals when user interacts with relevant elements
    init: () => {
        const html             = document.documentElement;
        const closeButtonClass = ".modal_information__close_button";
        const closeButton      = document.querySelector(closeButtonClass);
        if (!closeButton) {
            console.error(`Could not find the button with selector: ${closeButtonClass}`);
            return;
        };
        const asideVisibleClass = "aside_info_is_open";
        const asideClass        = ".modal_information__aside";
        const asideElement      = document.querySelector(asideClass);
        if (!asideElement) {
            console.error(`Could not find the information window with selector: ${asideClass}`);
            return;
        };
        const allInformationLists = asideElement.getElementsByTagName("li");
        closeButton.onclick = () => {
            html.classList.remove(asideVisibleClass);
            for (let i = 0; i < allInformationLists.length; i++) {
                allInformationLists[i].style.display = "none";
            }
        };
        asideElement.onclick = function (e) {
            if (e.target !== this) {
                return;
            }
            html.classList.remove(asideVisibleClass);
            for (let i = 0; i < allInformationLists.length; i++) {
                allInformationLists[i].style.display = "none";
            }
        };
    }
};
