module.exports = {
    // direct user to search results on tghn monolithic codebase
    init: () => {
        // find the search form
        const searchFormClass = "#global_header__search__form";
        const searchForm      = document.querySelector(searchFormClass);
        if (!searchForm) {
            console.error(`Could not find the search form with selector: ${searchFormClass}`);
            return;
        };
        // find the search input
        const searchFieldClass = "#global_header__search__form__text_input";
        const searchField      = document.querySelector(searchFieldClass);
        if (!searchField) {
            console.error(`Could not find the search input field with selector: ${searchFieldClass}`);
            return;
        };
        // send user to the search results when form is submitted
        function searchSubmitHandler () {
            // assemble the new url and open a new tab
            const searchResultsUrl = searchForm.action + "search/?q=" + searchField.value;
            window.open(searchResultsUrl);
            // prevent form from being submitted, because request has already been called
            return false;
        }
        // attach submit handler function
        searchForm.onsubmit = searchSubmitHandler;
    }
};
