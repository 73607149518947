module.exports = {
    // open information modals when user
    // interacts with relevant buttons
    init: () => {
        const html            = document.documentElement;
        const openButtonClass = ".navigation_cards__button--about";
        const openButton      = document.querySelectorAll(openButtonClass);
        if (!openButton) {
            console.error(`Could not find any elements with the selector: ${openButtonClass}`);
            return;
        };
        const asideVisibleClass = "aside_info_is_open";
        for (let i = 0; i < openButton.length; i++) {
            let modalInfoID = openButton[i].dataset.modalId;
            let buttonCorrespondingInfoList = document.getElementById(modalInfoID);
            if (!buttonCorrespondingInfoList) {
                console.error(`Could not find the information item with ID: ${modalInfoID}`);
                return;
            };
            openButton[i].onclick = () => {
                html.classList.add(asideVisibleClass);
                buttonCorrespondingInfoList.style.display = "block";
            };
        }
    }
};
