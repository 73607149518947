// polyfill to enable us to use forEach on node lists in IE11
const ForEachPolyfill = require("./modules/foreach-polyfill");
ForEachPolyfill.init();

// interpolate between minimum ratio & maximum ratio, based on current viewport width
const TypeRatioInterpolation = require("./modules/type-ratio-interpolation");
TypeRatioInterpolation.init();

// open navigation modals when user interacts with relevant buttons
const AsideNavOpenButtons = require("./modules/aside-nav-open-buttons");
AsideNavOpenButtons.init();

// close navigation modals & all sub lists when user interacts with relevant elements
const AsideNavClose = require("./modules/aside-nav-close-elements");
AsideNavClose.init();

// toggle navigation modals sub-lists when user interacts with sub-lists' buttons
const AsideNavSubNavListToggle = require("./modules/toggle-nav-aside-sub-nav-lists");
AsideNavSubNavListToggle.init();

// open information modals when user interacts with relevant buttons
const AsideInfoOpenButtons = require("./modules/aside-info-open-buttons");
AsideInfoOpenButtons.init();

// close information modals when user interacts with relevant elements
const AsideInfoClose = require("./modules/aside-info-close-elements");
AsideInfoClose.init();

// detect focus source using a11y.js
const FocusAccessibility = require("./modules/focus-accessibility");
FocusAccessibility.init();

// open search form when user interacts with relevant button
const SearchOpen = require("./modules/search-open-button");
SearchOpen.init();

// close search form when user interacts with relevant elements
const SearchClose = require("./modules/search-close-elements");
SearchClose.init();

// direct user to search results on tghn monolithic codebase
const SearchFormHandler = require("./modules/search-form-handler");
SearchFormHandler.init();

// change 'Register / Sign in' button to 'User Signed In' when logged in
const UserSignedIn = require("./modules/user-signed-in");
UserSignedIn.init();
