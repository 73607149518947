module.exports = {
    // close search form when user interacts with relevant elements
    init: () => {
        const html                   = document.documentElement;
        const searchVisibleClass     = "search_is_open";
        // close search modal if user interacts with the search close button
        const searchCloseButtonClass = ".global_header__search__form__close_button";
        const searchCloseButton      = document.querySelector(searchCloseButtonClass);
        if (!searchCloseButton) {
            console.error(`Could not find any buttons with the selector: ${searchCloseButtonClass}`);
            return;
        };
        searchCloseButton.onclick = () => {
            html.classList.remove(searchVisibleClass);
        };
        // remove class from html element if search modal is open and user
        // interacts with anything outside of the visible search form
        const searchFormClass = ".global_header__search__form";
        const searchForm = document.querySelector(searchFormClass);
        if (!searchForm) {
            console.error(`Could not find any forms with the selector: ${searchFormClass}`);
            return;
        };
        searchForm.onclick = function (e) {
            if (e.target !== this) {
                return;
            }
            html.classList.remove(searchVisibleClass);
        };
    }
};
