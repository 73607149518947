module.exports = {
    // open search form when user interacts with relevant button
    init: () => {
        const html                  = document.documentElement;
        const searchVisibleClass    = "search_is_open";
        const searchOpenButtonClass = ".global_header__search__open";
        const searchOpenButton      = document.querySelector(searchOpenButtonClass);
        if (!searchOpenButton) {
            console.error(`Could not find any buttons with the selector: ${searchOpenButtonClass}`);
            return;
        };
        const searchTextInputClass = ".global_header__search__form__text_input";
        const searchTextInput      = document.querySelector(searchTextInputClass);
        if (!searchTextInput) {
            console.error(`Could not find the text input with the selector: ${searchTextInputClass}`);
            return;
        };
        searchOpenButton.onclick = () => {
            html.classList.add(searchVisibleClass);
            // give focus to the search text input
            searchTextInput.focus();
        };
    }
};
