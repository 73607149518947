module.exports = {
    // toggle navigation modals sub-lists when user interacts
    // with sub-lists' buttons
    init: () => {
        const subNavToggleButtonsClass  = ".modal_navigation__sub_list_button";
        const subNavListButtonOpenClass = "modal_navigation__sub_list_button--list_is_visible";
        const subNavListOpenClass       = "modal_navigation__sub_list--is_visible";
        const subNavToggleButtons       = document.querySelectorAll(subNavToggleButtonsClass);
        if (!subNavToggleButtons) {
            console.error(`Could not find any buttons with the selector: ${subNavToggleButtonsClass}`);
            return;
        };
        for (let i = 0; i < subNavToggleButtons.length; i++) {
            subNavToggleButtons[i].onclick = () => {
                subNavToggleButtons[i].classList.toggle(subNavListButtonOpenClass);
                subNavToggleButtons[i].nextElementSibling.classList.toggle(subNavListOpenClass);
            };
        }
    }
};
