module.exports = {
    init: () => {
        const globalHeaderUser = "global_header__user";
        // if cookie exists, hide 'Register / Sign in' anchors and display
        // a span with the text 'You are signed in'
        if (document.cookie.includes("TGHNUserSignedIn=True")) {
            const searchUserRegisterClass = ".".concat(globalHeaderUser);
            const searchUserRegisterElements = document.querySelectorAll(
                searchUserRegisterClass
            );

            if (searchUserRegisterElements.length === 0) {
                console.error(
                    `Could not find any elements with the selector: ${searchUserRegisterClass}`
                );
                return;
            }
            searchUserRegisterElements.forEach(element => {
                element.classList.add(
                    globalHeaderUser.concat("--is_signed_in")
                );
            });
        }
    }
};
