module.exports = {
    // open navigation modals when user interacts with relevant buttons
    init: () => {
        const html              = document.documentElement;
        const asideVisibleClass = "aside_nav_is_open";
        const openButtonClass   = ".navigation_cards__button--menu";
        const openButton        = document.querySelectorAll(openButtonClass);
        if (!openButton) {
            console.error(`Could not find any buttons with the selector: ${openButtonClass}`);
            return;
        };
        for (let i = 0; i < openButton.length; i++) {
            let modalNavID                 = openButton[i].dataset.modalId;
            let buttonCorrespondingNavList = document.getElementById(modalNavID);
            if (!buttonCorrespondingNavList) {
                console.error(`Could not find the navigation element with ID: ${modalNavID}`);
                return;
            };
            openButton[i].onclick = () => {
                html.classList.add(asideVisibleClass);
                buttonCorrespondingNavList.style.display = "block";
            };
        }
    }
};
