module.exports = {
    // close navigation modals and all sub lists when user interacts with relevant buttons
    init: () => {
        // get the html element
        const html = document.documentElement;
        // set the class to make the asides visible
        const asideVisibleClass = "aside_nav_is_open";
        // get the aside element
        const asideClass   = ".modal_navigation__aside";
        const asideElement = document.querySelector(asideClass);
        if (!asideElement) {
            console.error(`Could not find the aside with selector: ${asideClass}`);
            return;
        };
        // get the close button elements within the asides
        const closeButtonClass = ".modal_navigation__close_button";
        const closeButtons     = asideElement.querySelectorAll(closeButtonClass);
        if (!closeButtons) {
            console.error(`Could not find any buttons with selector: ${closeButtonClass}`);
            return;
        };
        // get all nav elements within the asides
        const navListClass    = ".modal_navigation";
        const allNavLists     = asideElement.querySelectorAll(navListClass);
        const subNavListClass = ".modal_navigation__sub_list";
        const allSubNavLists  = asideElement.querySelectorAll(subNavListClass);
        if (!allSubNavLists) {
            console.error(`Could not find any sub-navigation lists with selector: ${subNavListClass}`);
            return;
        };
        // set the class to make the nav list visible
        const subNavListOpenClass = "modal_navigation__sub_list--is_visible";
        // set the class for buttons that have a corresponding visible nav list
        const subNavListButtonOpenClass = "modal_navigation__sub_list_button--list_is_visible";
        // get all buttons that toggle visibility of nav lists
        const subNavToggleButtonsClass = ".modal_navigation__sub_list_button";
        const subNavToggleButtons      = asideElement.querySelectorAll(subNavToggleButtonsClass);
        // remove all visible classes/styles from aside and all nav lists
        function CloseAsideAndAllNavs () {
            html.classList.remove(asideVisibleClass);
            allNavLists.forEach(navList => {
                navList.style.display = "none";
            });
            allSubNavLists.forEach(subNavList => {
                subNavList.classList.remove(subNavListOpenClass);
            });
            subNavToggleButtons.forEach(toggleButton => {
                toggleButton.classList.remove(subNavListButtonOpenClass);
            });
        }
        // listen for clicks on the aside element
        asideElement.addEventListener("click", function (event) {
            // close the aside if the user interacts outside the nav
            if (event.target !== this) {
                return;
            }
            CloseAsideAndAllNavs();
        }, false);
        // listen for clicks on the nav close buttons
        closeButtons.forEach(closeButton => {
            closeButton.addEventListener("click", () => {
                CloseAsideAndAllNavs();
            }, false);
        });
    }
};
